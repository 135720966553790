
export const generateTicks = (maxValue: number) => {
    let tickCount = maxValue % 4 === 0 ? 4 : 5;
    let interval = Math.round(maxValue / tickCount);

    // Ensure interval is a "pretty" number, e.g. 10, 20, 30, etc.
    let magnitude = Math.pow(10, Math.floor(Math.log10(interval)));
    interval = Math.ceil(interval / magnitude) * magnitude;

    // If maxValue is not a multiple of the new interval, add an extra tick.
    if (maxValue % interval !== 0) {
        tickCount++;
    }

    const ticks = Array.from({length: tickCount}, (_, i) => i * interval);
    return ticks;
}
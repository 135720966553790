import {IBrandRegion, IYearlySummary} from "../../types.ts";

export const extractBrandSales = ({sales, maxPeriod, units = true}: { sales: IBrandRegion | undefined, maxPeriod: Date, units?: boolean}) => {
    const year = maxPeriod.getFullYear();
    const month = maxPeriod.getMonth() +1;
    let currentYTD = sales?.yearlySummary.find((y: IYearlySummary) => y.year === year)?.units || 0;
    if (!units) currentYTD = sales?.yearlySummary.find((y: IYearlySummary) => y.year === year)?.sales || 0;
    const target = sales?.target.target;
    const monthlyTarget = sales?.target.monthlyTarget!;
    const goalYTD =  monthlyTarget.filter((g: any) => g.month <= month).reduce((acc: number, curr: any) => acc + curr.target, 0)
    const targetYTD = (currentYTD / goalYTD) * 100;
    return {
        target,
        monthlyTarget,
        targetYTD,
        goalYTD,
        currentYTD
    }
}

import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

export const NordicFlag = () => {
    return (
        <SvgIcon viewBox="0 0 600 400">
            <rect x="0" y="0" width="777" height="480" fill="white"/>
            <path
                d="M 0 170 l 170 0 l 0 -170 l 140 0 l 0 170 l 467 0 l 0 140 l-467 0 l 0 170 l-140 0 l 0 -170 l-170 0 Z"
                fill="black"/>
            <path
                d="M 0 200 l 200 0 l 0 -200 l 80 0 l 0 200 l 497 0 l 0 80 l-497 0 l 0 200 l-80 0 l 0 -200 l-200 0 Z"
                fill="black"/>
        </SvgIcon>
    )
}
import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Title from './Title';
import {useContext, useEffect} from "react";
import {AppContext} from "../AppContext";
export const Log = () => {
    const {logs, setLastLoginTime} = useContext(AppContext);
    useEffect(() => {
        if (setLastLoginTime) setLastLoginTime(new Date());
    }, [logs])
    return (
        <React.Fragment>
            <Title>Recent updates</Title>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                logs.map((row: any) => (
                                <TableRow key={row.id}>
                                    <TableCell>{new Date(row.createdAt).toLocaleString('sv-SE')}</TableCell>
                                    <TableCell>{row.message}</TableCell>
                                </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </React.Fragment>
    );
}

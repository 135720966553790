import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { msalConfig } from './authentication/authConfig';
import useWebSocket from "react-use-websocket";
import {MessageDisplay} from "./components/MessageDisplay";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const socketUrl =`${process.env.REACT_APP_WEBSOCKET_API}`;
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const Root = () => {
    const [shouldConnect, setShouldConnect] = useState(true);
    const params = new URLSearchParams(window.location.search);

    const {lastMessage, sendMessage, getWebSocket} = useWebSocket(socketUrl,
        {
            onOpen: () => console.log('DEBUG: web socket opened'),
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: (closeEvent) => {
                console.log('WebSocket closed with code', closeEvent.code);
                return shouldConnect;
            },
        });
    return (
        <React.Fragment>

        {
            params.get('ppID') &&
                <React.StrictMode>
                    <ThemeProvider theme={darkTheme}>
                        <App lastMessage={lastMessage} sendMessage={sendMessage} getWebSocket={getWebSocket} setShouldConnect={setShouldConnect} ppID={params.get('ppID')??''} />
                    </ThemeProvider>
                </React.StrictMode>
        }
        {
            !params.get('ppID') &&
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} loadingComponent={MessageDisplay}>
                    <React.StrictMode>
                            <App lastMessage={lastMessage} sendMessage={sendMessage} getWebSocket={getWebSocket} setShouldConnect={setShouldConnect} />
                    </React.StrictMode>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        }
        </React.Fragment>
    );
}
root.render(<Root />);


import * as React from 'react';
import {LagKPI} from "./LagKPI";
import {LeadKPI} from "./LeadKPI";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {useContext} from "react";
import {AppContext} from "../../AppContext";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function DashboardGrid() {
    const {ppID, isDesktop} = useContext(AppContext);
    const theme = createTheme({
        palette: {
            mode: ppID ? 'dark': 'light',

        },
        typography: {
            h4: {
                fontWeight: 700,
            },
            h5: {
                fontWeight: 500,
            },
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        padding: '20px',
                        margin: '10px',
                    },
                },
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            {isDesktop &&
                <Paper>
                    <LagKPI/>
                    {!ppID && <LeadKPI/>}
                </Paper>
            }
            {
                !isDesktop &&
                <React.Fragment>
                    <LagKPI/>
                    {!ppID && <LeadKPI/>}
                </React.Fragment>
            }

        </ThemeProvider>

    );
}

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Dialog, Divider, List, ListItem, ListItemText, Popover} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
function formatNumberSwedishStyle(value: number) {
    if (isNaN(value)) return value;
    return new Intl.NumberFormat('sv-SE', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
}
interface TargetGroupGaugeProps {
    measure: string;
    description?: string;
    goalYTD?: number;
    currentYTD: number;
    goalYear?: number;
    maxValue?: number;
    targetGroup?: any;
    currentYear?: number;
}

const MoreInfoComponent = ({moreInfo, formatter, lastUpdate }:
                               {moreInfo: TargetGroupGaugeProps,
                                   lastUpdate?: Date,
                                   formatter: (gauge: TargetGroupGaugeProps) => JSX.Element }) => {
                    return (
                        <React.Fragment>
                            <Box marginBottom={2}>
                                {formatter({...moreInfo})}
                            </Box>
                        </React.Fragment>
                    )
}

// Formatting functions
const salesFormatter = (moreInfo: TargetGroupGaugeProps) => (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            {moreInfo.measure}
        </Typography>
        <Divider style={{ marginBottom: '10px' }} />
        <Typography  variant="subtitle2">
            <strong>Current YTD:</strong> {moreInfo.currentYTD}
        </Typography>
        <Typography variant="subtitle2">
            <strong>Goal YTD:</strong> {moreInfo.goalYTD}
        </Typography>
        <Typography variant="subtitle2">
            <strong>Goal {moreInfo.currentYear}:</strong> {moreInfo.goalYear}
        </Typography>
    </React.Fragment>
)

const activityFormatter = (gauge: TargetGroupGaugeProps) => (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            {gauge.description}
        </Typography>
        <Divider style={{ marginBottom: '10px' }} />
        <Typography variant="subtitle2">
            <strong>Current:</strong> {gauge.currentYTD} {gauge.measure.toLowerCase()}
        </Typography>
        <Typography variant="subtitle2">
            <strong>Goal:</strong> {gauge.goalYTD} {gauge.measure.toLowerCase()}
        </Typography>
    </React.Fragment>
)

const qualityFormatter = (gauge: TargetGroupGaugeProps) => (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            {gauge.measure}
        </Typography>
        <Divider style={{ marginBottom: '10px' }} />
        {
            gauge.currentYTD &&
            <Typography variant="subtitle2">
                <strong>Current:</strong> {formatNumberSwedishStyle(gauge.currentYTD)}
            </Typography>
        }
        {gauge.goalYTD &&
            <Typography variant="subtitle2">
                <strong>Goal YTD:</strong> {formatNumberSwedishStyle(gauge.goalYTD!)}
            </Typography>
        }
        <Typography variant="subtitle2">
            <strong>Goal:</strong> {formatNumberSwedishStyle(gauge.goalYear!)}
        </Typography>
    </React.Fragment>
)

const customerFormatter = (gauge: TargetGroupGaugeProps) => (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
            Consents {gauge.measure} doctors
        </Typography>
        <Divider style={{ marginBottom: '10px' }} />
        <Typography variant="subtitle2">
            <strong>Current:</strong> {gauge.currentYTD}
        </Typography>
        <Typography variant="subtitle2">
            <strong>Goal:</strong> {gauge.goalYTD}
        </Typography>
        <Typography variant="subtitle2">
            <strong>Customers in target group:</strong> {gauge.maxValue}
        </Typography>
        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
        <Typography variant="subtitle2">
            <strong>Target Group(s)</strong>
        </Typography>

        <List style={{ padding: 0 }}>
            {gauge?.targetGroup?.map((item: any, index: number) => (
                <ListItem key={item.id} style={{
                    padding: '2px 0',
                    backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'transparent'
                }}>
                    <ListItemText
                        primary={<Typography variant="subtitle2" style={{ paddingLeft: '10px', lineHeight: '0.8' }}>{`\u2022 ${item.name}`}</Typography>}
                    />
                </ListItem>
            ))}
        </List>
    </React.Fragment>
)


export const TextBox = ({ title, lastUpdate, value, type, moreInfo }: TextBoxProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const theme = useTheme();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? `popover-${title.replace(/\s+/g, '-')}` : undefined;

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minWidth: 330,
                maxWidth: 330,
                textAlign: 'left',
                '&:hover': {
                    boxShadow: theme.shadows[3],
                },

            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography component="div" variant="subtitle1" color="primary">
                    {title}
                </Typography>
                <IconButton onClick={handleClick} style={{ color: theme.palette.primary.main, padding: 0 }}>
                    <InfoIcon sx={{ fontSize: '20px' }} />
                </IconButton>
            </Box>
            <Box>
                <Typography component="p" variant="h5" dangerouslySetInnerHTML={{__html: value??''}}/>
                <Typography variant="caption" color="text.secondary">
                    Updated on {lastUpdate?.toLocaleString('sv-SE')}
                </Typography>
            </Box>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2 }}>

                        <Box >
                            {
                                type === 'Sales' && <MoreInfoComponent moreInfo={moreInfo} formatter={salesFormatter} lastUpdate={lastUpdate} />
                            }
                            {
                                type === 'Activity' && <MoreInfoComponent moreInfo={moreInfo}  formatter={activityFormatter} lastUpdate={lastUpdate} />
                            }
                            {
                                type === 'Customer' && <MoreInfoComponent moreInfo={moreInfo}  formatter={customerFormatter} lastUpdate={lastUpdate} />
                            }
                            {
                                type === 'Quality' && <MoreInfoComponent moreInfo={moreInfo}  formatter={qualityFormatter} lastUpdate={lastUpdate} />
                            }
                            {
                                type === 'Quantity' && <MoreInfoComponent moreInfo={moreInfo}  formatter={qualityFormatter} lastUpdate={lastUpdate} />
                            }
                        </Box>

                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: theme.spacing(1),
                            top: theme.spacing(1),
                            color: theme.palette.text.secondary
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Popover>
        </Paper>
    );
};

interface TextBoxProps {
    title: string;
    value?: string;
    type: 'Sales' | 'Activity' | 'Customer' | 'Quality' | 'Quantity' | 'Account planning',
    lastUpdate?: Date,
    moreInfo?: any
}
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    Tooltip,
BarChart, Bar
} from 'recharts';
import Title from '../Title';
import moment from "moment";

import {useEffect} from "react";
import {generateTicks} from "./helper";

type DataType = {
    year: number,
    month: number,
    value: number
};
export const CustomBarChart = ({title, data, yAxisTitle}: {title: string, data: any, yAxisTitle: string}) => {
    const theme = useTheme();
    let maxValue = Math.round(((Math.max(...data.map((m: any) => m.value)) * 1.5) / 10) * 10);
    if (maxValue < 13) maxValue = 9;
    const [chartData, setChartData] = React.useState<DataType[]>([]);

    //Barchart does not like missing months, so we need to fill in the blanks
    useEffect(() => {
        if (data.length === 1) {
            let dummyData: DataType[] = [];
            for (let i = 1; i <= 12; i++) {
                if (i === data[0].month) {
                    dummyData.push(data[0]);
                } else {
                    dummyData.push({year: data[0].year, month: i, value: 0});
                }
            }
            setChartData(dummyData);
        } else {
            setChartData(data);
        }
    }, [data]);

    interface CustomTooltipProps {
        active?: boolean;
        payload?: Array<any>;
        label?: string;
    }

    const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const month = moment(label, 'M').format('MMMM');
            const value = payload[0].value;

            const tooltipStyle: React.CSSProperties = {
                background: 'white',
                border: '1px solid #ccc',
                padding: '10px',
            };

            return (
                <div style={tooltipStyle}>
                    <p style={{ margin: 0 }}>{`${month} : ${value}`}</p>
                </div>
            );
        }

        return null;
    };
    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 260,
                minHeight: 260,
                minWidth: 330,
                maxWidth: 330
            }}
        >
            <Title>{title}</Title>
            <ResponsiveContainer>
                <BarChart
                    data={chartData}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis
                        padding={{ left: 15, right: 15 }}
                        dataKey="month"
                        scale="time"
                        type="number"
                        angle={-45}
                        domain={[1, 12]}
                        interval={0}
                        ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                        tickFormatter={value => moment(value, 'M').format('MMM')}
                        stroke={theme.palette.text.secondary}
                        style={
                            {
                                ...theme.typography.caption,
                                fontSize: 8
                            }
                        }
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}
                        style={{
                            ...theme.typography.body2,
                            fontSize: 10
                        }}
                        domain={[0, maxValue]}
                        tickFormatter={(value: number) => Math.floor(value).toString()}
                        ticks={generateTicks(maxValue)}

                    >
                        <Label
                            angle={270}
                            position="left"
                            style={{
                                textAnchor: 'middle',
                                fill: theme.palette.text.primary,
                                ...theme.typography.body1,
                            }}
                        >
                            {yAxisTitle}
                        </Label>
                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />

                    <Bar
                        isAnimationActive={true}
                        data={chartData}
                        fill="#1976d2"
                        dataKey="value"
                        label={{ position: 'top', fontSize: 8 }}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Paper>
    );
}

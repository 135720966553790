import Grid from "@mui/material/Grid";
import {Box, Typography} from "@mui/material";
import {GridRow} from "./GridRow";
import {TextBox} from "./TextBox";
import * as React from "react";
import {CustomPieChart} from "../Charts/CustomPieChart";
import {useContext} from "react";
import {AppContext} from "../../AppContext";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

export const QualityKPI = () => {
    const theme = useTheme();
    const {repEmails, country, lastUpdated, maxPeriod, events, isDesktop} = useContext(AppContext);
    const getLastUpdated = (type: string) => {
        const tmpLastUpdated = lastUpdated[country][type];
        if (tmpLastUpdated) return new Date(tmpLastUpdated);
        return new Date();
    }
    const repEmailsTarget = repEmails?.target?.openRate??0;
    let repEmailOpenRate =  (repEmails?.totalOpenedRepEmails??0) / (repEmails?.totalSentRepEmails??0);
    const repEmailIsOnTarget = repEmailOpenRate >= repEmailsTarget;
    repEmailOpenRate = repEmailOpenRate * 100;

    const npsTarget = events?.target?.npsScore??0;
    const npsScore = events?.npsScore??0;
    const npsIsOnTarget = npsScore >= npsTarget;
    const noData = `<span style="font-style: italic; font-size: small" >No data yet...</span>`;
    const QualityKPIInner = () => {
        return (
        <React.Fragment>
            <Grid item xs={12} marginBottom={3} >
                <Typography paddingBottom={0} paddingTop={1} paddingLeft={2} variant={'h5'}>Quality</Typography>
            </Grid>
            <GridRow
                Col1={() => <TextBox
                    type={'Quality'}
                    title="Company NPS"
                    value={noData}
                    lastUpdate={getLastUpdated('sales')}
                />}
                Col2={() => <TextBox
                    type={'Quality'}
                    title="Meeting NPS"
                    value={!npsScore ? noData : `<span style="color: ${npsIsOnTarget ? 'green' : 'red'}">${npsScore.toFixed(1)}</span>`}
                    lastUpdate={getLastUpdated('events')}
                    moreInfo={{
                        measure: 'Average Meeting NPS (Net Promoter Score)',
                        currentYTD: `${npsScore.toFixed(1)} (average from ${events?.replies??0} replies)`,
                        goalYear: `≥ ${npsTarget.toFixed(1)}`
                    }}
                />}
                Col3={() => <TextBox
                    type={'Quality'}
                    title="RepEmail Open Rate"
                    value={!repEmailOpenRate ? noData : `<span style="color: ${repEmailIsOnTarget ? 'green' : 'red'}">${repEmailOpenRate?.toFixed(1)} %</span>`}
                    lastUpdate={getLastUpdated('consents')}
                    moreInfo={{
                        measure: 'RepEmail Open Rate',
                        currentYTD: `${isNaN(repEmailOpenRate) ? 0 :  repEmailOpenRate.toFixed(1)} %`,
                        goalYear: `${repEmailsTarget * 100} %`
                    }}
                />} />

            <GridRow
            />
            <GridRow/>
        </React.Fragment>
            )
    }
    return (
        <React.Fragment>
            {
                isDesktop &&
                    <Paper sx={{  backgroundColor: '#eff6fb', width: '100%', alignItems: 'center',m:0}}>
                        <QualityKPIInner/>
                    </Paper>
            }
            {
                !isDesktop &&
                <Box sx={{  backgroundColor: '#eff6fb'}}>
                    <QualityKPIInner/>
                </Box>
            }
        </React.Fragment>
    )
}
import { useEffect, useState } from 'react';
import { SendMessage } from 'react-use-websocket';
import { Status } from '../../types/Status';
import {getIdToken} from "../../authentication/azureAuthProvider";
import {fireAuthError} from "../../authentication/fireAuthError";

interface WebSocketStatus {
    payload?: any;
    authenticating?: boolean;
}

const useWebSocketConnection = (
    lastMessage: MessageEvent<any> | null,
    sendMessage: SendMessage,
    getWebSocket: any,
    setShouldConnect: any,
    setIsAuthorized: any,
    ppId?: string
): WebSocketStatus => {
    const [payload, setPayload] = useState<any>(null);
    const [authenticating, setAuthenticating] = useState<boolean>(true);
    useEffect(() => {
        const handleStatusCode = (statusCode: number) => {
            const isStatusSuccess = statusCode === Status.SUCCESS;
            const isStatusNotAuthenticated = statusCode === Status.NOT_AUTHENTICATED;
            const isStatusNotAuthorized = statusCode === Status.NOT_AUTHORIZED;

            if (isStatusSuccess){
                setAuthenticating(false);
                setIsAuthorized(true);
                return;
            }


            if (isStatusNotAuthenticated || isStatusNotAuthorized) {
                setShouldConnect(false);
                getWebSocket().close();
                if (isStatusNotAuthenticated) fireAuthError(401, 'Not authenticated');
                if (isStatusNotAuthorized) fireAuthError(403, 'Not authorized');
            }
        };

        if (lastMessage !== null) {
            const payload = JSON.parse(lastMessage.data);
            handleStatusCode(payload.statusCode);
            if (payload.routeKey === 'getData' || payload.routeKey === 'pushData') setPayload(payload.data);
        }
    }, [lastMessage]);

    useEffect(() => {
        const getData = async () => {
            if (ppId) {
                sendMessage(JSON.stringify({
                    action: 'getData',
                    tokenType: 'pp',
                    token: `${ppId}`
                }));
            } else {
                sendMessage(JSON.stringify({
                    action: 'getData',
                    tokenType: 'azure',
                    token: `${await getIdToken()}`
                }));
            }
        }
        getData();

    }, []);

    return { payload, authenticating };
};

export default useWebSocketConnection;

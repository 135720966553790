import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { Status } from '../types/Status';
type Messages = {
    [key: number]: {
        title: string,
        body: string
    }
};

const messages: Messages = {
    403: {
        title: "Access Denied",
        body: "You're employed by Tillotts, but your access to this dashboard is limited based on your region. Currently, this dashboard is exclusively available to our employees in the Nordics. If you believe you should have access, please contact support."
    },
    401: {
        title: "Authentication Failed",
        body: "This application is exclusively for Tillotts employees. It seems you're not authenticated. Please log in to gain access. If you believe this is an error, please contact support."
    },
    102: {
        title: "Authenticating",
        body: "Hang tight! We're currently verifying your credentials. You will have access shortly."
    },
    600: {
        title: "Authentication Failed / Device Location Alert",
        body: "You are attempting to log in from a device located at the Bromma office, but your device was not authenticated. Please try restarting the device. If the problem persists, contact Joakim Sannheim."
    }
};

export const MessageDisplay = ({ type = Status.PROCESSING, ...otherProps }) => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%"
        bgcolor="background.default"
    >
        <Box width={'50%'}>
            <Typography variant={window.outerWidth < 400 ? 'h4' : 'h3'} color="primary.main" gutterBottom align={'center'}>
                {messages[type].title}
            </Typography>
            <Typography component="div" variant="body1" color="text.primary" gutterBottom align={'center'}>
                {messages[type].body}
                {type === 102 && (
                    <Box mt={2} alignItems={'center'}>
                        <CircularProgress color="primary" />
                    </Box>
                )}
            </Typography>
        </Box>
    </Box>
);


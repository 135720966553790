
import React, {useEffect} from "react";
import { Chart } from "react-google-charts";
import Title from "../Title";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {useTheme} from "@mui/material/styles";
import {Dialog, Divider} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";

const MoreInfoComponent = ({title, description, formula}: {title: string, description: string, formula: string}) => {
    return (
        <React.Fragment>
            <Box marginBottom={2}>
                <Typography variant="h5" gutterBottom>{title}</Typography>
            </Box>
            <Divider style={{ margin: '10px 0' }} />
            <Box marginBottom={2} >
                <p>{description}</p>
                <p><code style={{fontSize: 12}}>{formula}</code></p>
            </Box>
        </React.Fragment>
    )
}
export const CustomGoogleLineChart = ({vAxisTitle, title, data, format = 'percent', description, formula}: {vAxisTitle: string, title: string, data: any, format?: string, description: string, formula: string}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const options = {
        language: 'sv',
        backgroundColor: theme.palette.background.default,
        bar: {groupWidth: "50%"},
        chartArea: { width: "70%", height: "60%" },
        legend: { position: "bottom", textStyle: { color: theme.palette.text.primary } },
        colors: ['#42A5F5', '#FFA726'],
        hAxis: {

            textStyle: {
                color: theme.palette.text.primary,
                fontSize: 7, // specify the desired font size
            },
            slantedText: true,
            slantedTextAngle: 35, // here you can put the degree of the angle you prefer
        },
        vAxis: {
            title: vAxisTitle,
            titleTextStyle: {
                color: theme.palette.text.primary,
                italic: false, // set to true if you want italic
                fontWeight: 400,
                fontSize: 16,
                lineHeight: 1.5,
                letterSpacing: '0.00938em'
            },
            minValue: 0,
            format: format === 'percent' ? '##.#%' : format,
            gridlines: {
                color: theme.palette.divider,
                count: 5
            },
            textStyle: {
                color: theme.palette.text.primary,
                fontSize: 7, // specify the desired font size
            },
        },
        tooltip: {
            isHtml: true
        },
        fontSize: 8,
        animation:{
            startup: true,
            duration: 1000,
            easing: 'out',
        },
        curveType: "function",
    };
    const columnNames = data[0];

    // Skapa den modifierade datan med anpassade tooltips
    const modifiedData = data.map((row: any, rowIndex: number) => {
        if (rowIndex === 0) {
            // För varje serie, lägg till en tooltip-kolumn
            return row.flatMap((columnName: any, columnIndex: number) =>
                columnIndex === 0 ? columnName : [columnName, { role: 'tooltip', type: 'string', 'p': { 'html': true } }]
            );
        } else {
            // Skapa en separat tooltip för varje värde
            return row.flatMap((cell: any, columnIndex: number) => {
                return columnIndex === 0 ? cell : [cell, `<div style="margin:5px;font-size: 10px; width: 130px;"><b>${row[0]}</b><div>${columnNames[columnIndex]}:  <b>${(cell * 100).toFixed(1)}%</b></div></div>`]
            }

            );
        }
    });
    return (
        <Paper
            sx={{
                pt: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 260,
                minHeight: 260,
                minWidth: 330,
                maxWidth: 330,
            }}
        >
            <Box sx={{ pl: 2, pr: 2,pb: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title>{title}</Title>
                <IconButton onClick={handleClick} style={{ color: theme.palette.primary.main, padding: 0 }}>
                    <InfoIcon sx={{fontSize: '20px'}} />
                </IconButton>
            </Box>
            <Chart
                chartType="LineChart"
                width={"100%"}
                height={"200px"}
                data={modifiedData}
                options={options}
            />
            <Dialog
                id={id}
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiDialog-paper': {
                        padding: theme.spacing(2),
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        position: 'relative'
                    }
                }}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: theme.spacing(1),
                        color: theme.palette.text.secondary
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box sx={{p:2}}>
                    <Box minWidth={matches ? 250 : 350}>
                        <MoreInfoComponent title={title} description={description} formula={formula}  />
                    </Box>
                </Box>
            </Dialog>
        </Paper>
    );
}

import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogTitle, List, ListItem, ListItemText} from '@mui/material';
import CountryFlag from "react-country-flag";
import Grid from "@mui/material/Grid";
import {AppContext} from "../AppContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import SvgIcon from "@mui/material/SvgIcon";
import {NordicFlag} from "./NordicFlag";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

const SelectCountry = ({open, setOpen}: {open: boolean, setOpen: any}) => {
    const {country, setCountry, ppID} = useContext(AppContext);
    const [selectedCountry, setSelectedCountry] = useState(country);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const translateCountry = (countryCode: string) => {
        switch (countryCode) {
            case 'DK':
                return 'Denmark';
            case 'FI':
                return 'Finland';
            case 'NO':
                return 'Norway';
            case 'SE':
                return 'Sweden';
            case 'Sweden':
                return 'SE';
            case 'Norway':
                return 'NO';
            case 'Finland':
                return 'FI';
            case 'Denmark':
                return 'DK';
            default:
                return 'DK';
            case 'Nordic':
                return 'Nordic';
        }
    }
    const handleListItemClick = (value: any) => {
        setSelectedCountry(translateCountry(value));
        // @ts-ignore
        setCountry(translateCountry(value));
        // @ts-ignore
        localStorage.setItem('country', translateCountry(value));
        handleClose();
    };

    const countries = ['DK', 'FI', 'NO', 'SE']; // Add or change the country codes according to your needs
    useEffect(() => {
        if (ppID) {
            let currentIndex = 0;
            const array = ['SE', 'DK', 'NO', 'FI', 'Nordic'];

            setInterval(() => {
                handleListItemClick(array[currentIndex]);
                currentIndex = (currentIndex + 1) % array.length;
            }, 30000);
        }
    }, []);
    return (
        <div>
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Please pick your country</DialogTitle>
                <Grid container spacing={2} sx={{mb: 2}}>
                    {countries.map((countryCode: string) => (
                        <Grid item xs={2} key={countryCode}>
                            <ListItem button onClick={() => handleListItemClick(countryCode)}>
                                <CountryFlag
                                    countryCode={countryCode}
                                    svg
                                    style={{
                                        width: matches ? '1em': '2em',
                                        height: matches ? '1em': '2em',
                                    }}
                                />
                                <ListItemText sx={{pl: 1,
                                    '& .MuiTypography-root': {
                                    fontSize: matches ? '12px !important': 'unset',
                                },}} primary={countryCode} />
                            </ListItem>
                        </Grid>
                    ))}
                    <Grid item xs={2} key={'Nordic'}>
                        <ListItem button onClick={() => handleListItemClick('Nordic')}>

                            <NordicFlag/>

                            <ListItemText sx={{pl: 1,'& .MuiTypography-root': {
                                    fontSize: matches ? '12px !important': 'unset',
                                }}} primary={'Nordic'} />
                        </ListItem>
                    </Grid>
                </Grid>
            </Dialog>
            <div style={{paddingLeft: 2, cursor: 'pointer'}} onClick={() => setOpen(true)} >
                {selectedCountry === 'Nordic' && <NordicFlag/>}
                {selectedCountry !== 'Nordic' &&
                    <CountryFlag
                        countryCode={translateCountry(selectedCountry)}
                        svg
                        style={{
                            width: '2em',
                            height: '2em',
                        }}
                    />}

            </div>
        </div>
    );
}

export default SelectCountry;
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MyAppBar from './AppBar';
import MyDrawer from './Drawer';
import DashboardGrid from './DashboardGrid';
import {Dialog} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Copyright} from "@mui/icons-material";
import {Log} from "./Log";
import {useContext} from "react";
import {AppContext} from "../AppContext";

const mdTheme = createTheme();

export default function Dashboard() {
    const [open, setOpen] = React.useState(false);
    const [openLog, setOpenLog] = React.useState(false);
    const {ppID} = useContext(AppContext);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenLog(true);
    };
    const handleClose = () => {
        setOpenLog(false);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <MyAppBar
                    position="absolute"
                    open={open}
                    handleDrawerOpen={toggleDrawer}
                    handleNotificationsClick={handleClick}
                />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}
                >
                    <Container maxWidth="lg" sx={{ mt: 10, mb: 4}}  >
                        <DashboardGrid />
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
            <Dialog
                open={openLog}
                maxWidth={'xl'}
                onClose={handleClose}
                sx={{
                    '& .MuiDialog-paper': {
                        padding: mdTheme.spacing(2),
                        backgroundColor: mdTheme.palette.background.default,
                        color: mdTheme.palette.text.primary,
                        position: 'relative'
                    }
                }}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: mdTheme.spacing(1),
                        top: mdTheme.spacing(1),
                        color: mdTheme.palette.text.secondary
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box sx={{p:2}}><Log /></Box>
            </Dialog>
        </React.Fragment>
    );
}

import Grid from "@mui/material/Grid";
import * as React from "react";
import Paper from "@mui/material/Paper";
import {useContext} from "react";
import {AppContext} from "../../AppContext";

export const GridRow = ({Col1, Col2, Col3}: {Col1?: any, Col2?: any,Col3?: any}) => {
    const {isDesktop} = useContext(AppContext);
    return (
        <Grid container spacing={2} marginLeft={isDesktop ? '-20px' : '-12px'} >
            <Grid item xs={12} sm={6} md={4} minWidth={330} >
                {Col1 && <Col1/>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}  minWidth={330}>
                {Col2 && <Col2/>}
            </Grid>
            <Grid item xs={12} sm={6} md={4}  minWidth={330}>
                {Col3 && <Col3/>}
            </Grid>
    </Grid>
        )
}
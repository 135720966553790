import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import {useContext, useEffect} from "react";
import {AppContext} from "../AppContext";
import SelectCountry from "./SelectCountry";
import UpdateIcon from '@mui/icons-material/Update';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
interface MyAppBarProps extends MuiAppBarProps {
    open?: boolean;
    handleDrawerOpen?: () => void;
    handleNotificationsClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const drawerWidth: number = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<MyAppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MyAppBar: React.FC<MyAppBarProps> = ({
                                               open = false,
                                               handleDrawerOpen = () => {},
                                               handleNotificationsClick = () => {},
                                               ...other
                                           }) => {
    const {ppID, country, logs, lastLoginTime, setCountry} = useContext(AppContext);
    const [openCountrySelector, setOpen] = React.useState((!localStorage.getItem("country") && !ppID))
    const [numUpdates, setNumUpdates] = React.useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    useEffect(() => {
        if (lastLoginTime) {
            setNumUpdates(logs.filter((log: any) => new Date(log.createdAt) > new Date(lastLoginTime)).length);
        }
    }, [logs, lastLoginTime]);
    return (
    <AppBar position="absolute" open={open}>
        <Toolbar
            sx={{
                pr: '24px', // keep right padding when drawer closed
            }}
        >
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
            >
                Dashboard - {country}
            </Typography>
            <SelectCountry open={openCountrySelector} setOpen={setOpen}/>
            <IconButton color="inherit" onClick={handleNotificationsClick}>
                <Badge badgeContent={numUpdates} color="secondary">
                    <UpdateIcon />
                </Badge>
            </IconButton>
        </Toolbar>
    </AppBar>
)};

export default MyAppBar;

import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    Tooltip,
    Legend
} from "recharts";
import Title from "../Title";
import moment from "moment";
import {generateTicks} from "./helper";
import {useContext} from "react";
import {AppContext} from "../../AppContext";

export const CustomLineChart = ({ title, data, goal, goalMonth, type = 'unit'}: {title: string, data: any, goal: number, goalMonth: any[], type?: string}) => {
    const {maxPeriod} = useContext(AppContext);
    const theme = useTheme();
    const currentYear = maxPeriod.getFullYear();
    const prevYear = maxPeriod.getFullYear() - 1;
    const maxValue = Math.round(goal);

    const convertData = (data: any) => {
        const res: any = [];

            data.map((m1: any) => {
                let record = res.find((r: any) => r.month === m1.month);
                const target = goalMonth.filter(g => g.month <= m1.month).reduce((acc, curr) => acc + curr.target, 0);
                if (!record) {
                    res.push({month: m1.month, target});
                    record = res.find((r: any) => r.month === m1.month);
                }
                if (record) {
                    if (type === 'unit') {
                        const sumUnits = data.filter((r: any) => r.month < m1.month && r.year === m1.year)
                            .reduce((acc: number, curr: any) => acc + curr.units, 0);
                        record[m1.year] = (isNaN(sumUnits) ? 0 : sumUnits) + m1.units;
                    } else {
                        const sumSales = data.filter((r: any) => r.month < m1.month && r.year === m1.year)
                            .reduce((acc: number, curr: any) => acc + curr.sales, 0);
                        record[m1.year] = (isNaN(sumSales) ? 0 : sumSales) + m1.sales;
                    }
                }
            });
        return res.sort((a: any, b: any) => a.month - b.month);
    };
    interface CustomTooltipProps {
        active?: boolean;
        payload?: Array<any>;
        label?: string;
    }
    const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
        const {maxPeriod} = useContext(AppContext);
        const currentYear = maxPeriod.getFullYear();
        if (active && payload && payload.length) {
            const month = moment(label, "M").format("MMMM");
            const twoYearsAgo = payload.find( f => f.dataKey === currentYear - 2 )?.value.toLocaleString("sv-SE");
            const prevYearValue = payload.find( f => f.dataKey === currentYear - 1 )?.value.toLocaleString("sv-SE");
            const currentYearValue = payload.find( f => f.dataKey === currentYear )?.value.toLocaleString("sv-SE");
            const target = Math.round(payload.find( f => f.dataKey === 'target' )?.value).toLocaleString("sv-SE");
            const tooltipStyle: React.CSSProperties = {
                background: "white",
                border: "1px solid #ccc",
                padding: "10px",
            };

            return (
                <div style={tooltipStyle}>
                    <p style={{ margin: 0 }}>{`${month}`}</p>
                    <p style={{ margin: 0 }}>{`${prevYear-1} : ${twoYearsAgo}`}</p>
                    <p style={{ margin: 0 }}>{`${prevYear} : ${prevYearValue}`}</p>
                    {
                        currentYearValue && <p style={{ margin: 0 }}>{`${currentYear} : ${currentYearValue}`}</p>
                    }
                    <p style={{ margin: 0 }}>{`Target : ${target}`}</p>
                </div>
            );
        }

        return null;
    };
    return (
        <Paper
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 260,
                minHeight: 260,
                minWidth: 330,
                maxWidth: 330,
            }}
        >
            <Title>{title}</Title>
            <ResponsiveContainer>
                <LineChart
                    margin={{
                        top: 16,
                        right: 10,
                        bottom: 0,
                        left: 10,
                    }}
                >
                    <XAxis
                        padding={{ left: 15, right: 15 }}
                        dataKey="month"
                        scale="time"
                        type="number"
                        angle={-45}
                        domain={[1, 12]}
                        interval={0}
                        ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                        tickFormatter={value => moment(value, "M").format("MMM")}
                        stroke={theme.palette.text.secondary}
                        style={
                            {
                                ...theme.typography.caption,
                                fontSize: 8
                            }
                        }
                    />
                    <YAxis
                        stroke={theme.palette.text.secondary}

                        style={{
                            ...theme.typography.body2,
                            fontSize: 10
                        }}
                        domain={[0, maxValue]}
                        tickFormatter={(value: number) => `${Math.floor(value).toLocaleString("sv-SE")}`}
                        ticks={generateTicks(maxValue)}

                    >
                        {
                            type === 'unit' &&  <Label
                                angle={270}
                                position="left"
                                style={{
                                    textAnchor: "middle",
                                    fill: theme.palette.text.primary,
                                    ...theme.typography.body1,
                                }}
                            >
                                Units
                            </Label>
                        }

                    </YAxis>
                    <Tooltip content={<CustomTooltip />} />


                    <Line
                        isAnimationActive={true}
                        data={convertData(data).filter((s: any) => s.hasOwnProperty(prevYear-1))}
                        type="monotone"
                        dataKey={prevYear-1}
                        strokeDasharray="3 3"
                        stroke={'grey'}
                        dot={false}
                    />
                    <Line
                        isAnimationActive={true}
                        data={convertData(data).filter( (s: any) => s[prevYear] !== null)}
                        type="monotone"
                        dataKey={prevYear}
                        strokeDasharray="3 3"
                        stroke={theme.palette.secondary.main}
                        dot={false}
                    />
                    <Line
                        isAnimationActive={true}
                        data={convertData(data).filter( (s: any) => s[currentYear] !== null)}
                        type="monotone"
                        dataKey={currentYear}
                        stroke={theme.palette.primary.main}
                        dot={false}
                    />
                    <Line
                        isAnimationActive={true}
                        data={convertData(data).filter( (s: any) => s.target !== null)}
                        type="monotone"
                        dataKey={"target"}
                        stroke={"green"}
                        dot={false}
                    />
                    <Legend wrapperStyle={theme.typography.caption} verticalAlign="top" align="right" />
                    {
                        /*
                    }
                    <ReferenceLine type="monotone" y={goal} fill="black" stroke="black" strokeDasharray="3 3">
                        <Label fontSize={'small'} position={"top"}>{`Target - ${Math.round(goal).toLocaleString('sv-SE')}`} </Label>
                    </ReferenceLine>
                    {
                         */
                    }
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
};

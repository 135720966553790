import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import * as React from "react";
import {QualityKPI} from "./QualityKPI";
import {QuantityKPI} from "./QuantityKPI";
import {AccountPlanningKPI} from "./AccountPlanningKPI";
import Box from "@mui/material/Box";

export const LeadKPI = () => {
    return (
        <Grid container paddingTop={4} >
            <Grid item xs={12} marginBottom={3} textAlign={'center'}>
                <Typography variant={'h4'}>Lead KPIs</Typography>
            </Grid>
                <QualityKPI/>
                <QuantityKPI/>
                <AccountPlanningKPI/>

        </Grid>
            )
}